import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import SecondPage from "./pages/SecondPage";
import ThirdPage from "./pages/ThirdPage";
import ContinentScreen from "./pages/ContinentScreen";

const AppRouter = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<ContinentScreen />} />
        <Route path="/second" element={<SecondPage />} />
        <Route path="/third" element={<ThirdPage />} />
        <Route path="/continents" element={<ContinentScreen />} />
      </Routes>
    </Router>
  );
};

export default AppRouter;
