import React from 'react';

const Card = ({ backgroundClass, logoSrc, logoAlt, buttonText, text }) => {
  return (
    <div className={`page_2-card ${backgroundClass}`}>
      {logoSrc && (
        <div className="page_2-logo-container">
          <img src={logoSrc} alt={logoAlt} className="page_2-logo" />
        </div>
      )}
      <div className="page_2-overlay">
        {text && <p className="page_2-text">{text}</p>}
        <button className="page_2-button">{buttonText}</button>
      </div>
    </div>
  );
};

export default Card;
