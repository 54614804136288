import React from 'react';
import GridCard from '../components/GridCard';
import '../styles/ThirdPage.css';

const ThirdPage = () => {
  return (
    <div className="page_3-background">
      <div className="page_3-gradient">
        <div className="page_3-grid">
        <GridCard
          className="page_3-card-small page_3-slide-left Page_3_image1"
          text="¿Quieres viajar a Miami u Orlando?"
          buttonText="REGÍSTRATE AQUÍ"
          logoSrc="/Logo blanco.png"
          logoAlt="Club Viajemos Logo"
          logoPosition="left"
        />
        <GridCard
          className="page_3-card-large page_3-slide-bottom Page_3_image2"
          logoSrc="/Logo blanco.png"
          logoAlt="Club Viajemos Logo"
          buttonText="INGRESA"
          planeSrc="/Avion.png"
          logoPosition="left"
        />
        <GridCard
          className="page_3-card-small page_3-no-animation Page_3_image3"
          logoSrc="/Logo_2.png"
          logoAlt="Viajemos Travel Club Logo"
          buttonText="VER VIDEOS"
          logoPosition="regth"
        />

        </div>
      </div>
    </div>
  );
};

export default ThirdPage;
