import React from 'react';
import Card from '../components/Card';
import '../styles/SecondPage.css';

const SecondPage = () => {
  return (
    <div className="page_2-background">
      <div className="page_2-gradient">
        <div className="page_2-row">
          <Card
            backgroundClass="fill-background fill-background-1"
            logoSrc="/Logo-03.png"
            logoAlt="Visa Logo"
            buttonText="REGÍSTRATE"
          />
          <Card
            backgroundClass="fill-background fill-background-2"
            // logoSrc="/Certificados-03.png"
            logoAlt="Certificado Logo"
            buttonText="SOY BROKER"
          />
          <Card
            backgroundClass="fill-background fill-background-3"
            text="Confirma tu Certificado de Viajes"
            buttonText="AQUÍ"
          />
        </div>
      </div>
    </div>
  );
};

export default SecondPage;
