import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/Header.css';

const Header = () => {
  return (
    <header className="page_1-header fadeIn-1s">
      <img src="./logo.png" alt="Club Viajemos" className="page_1-logo" />
      <nav className="page_1-nav">
        <Link to="/continents" className="page_1-nav-link">BROKERS</Link>
        <Link to="/third" className="page_1-nav-link">CONTACTO</Link>
      </nav>
    </header>
  );
};

export default Header;
