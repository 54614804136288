import React from 'react';
import '../styles/GridCard.css';

const GridCard = ({ className, logoSrc, logoAlt, text, buttonText, planeSrc, logoPosition }) => {
  const logoClass = logoPosition === 'left' ? 'page_3-logo-left' : 'page_3-logo-right';

  return (
    <div className={`page_3-card ${className}`}>
      {logoSrc && (
        <div className={`page_3-logo-container ${logoClass}`}>
          <img src={logoSrc} alt={logoAlt} className="page_3-logo" />
        </div>
      )}
      {text && (
        <div className="page_3-text-container">
          <p className="page_3-text">{text}</p>
        </div>
      )}
      {planeSrc && (
        <div className="page_3-plane-bottom">
          <img src={planeSrc} alt="Avión" className="page_3-plane" />
        </div>
      )}
      <button className="page_3-button">{buttonText}</button>
    </div>
  );
};

export default GridCard;
